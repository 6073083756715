import { useContext, useMemo, useState } from 'react'
import styles from './NotificationBar.module.css'
require('./NotificationBar.less')
import { useDispatch, useSelector } from 'react-redux'
import { LEGACY_SUBSCRIPTION_PLAN, SubscriptionStatus, claimsRoles, industries, paths, subscriptionLimit, topNotificationType } from '../../utils/constants'
import { useRouter } from 'next/router'
import moment from 'moment'
import { useEffect } from 'react'
import { Button, Carousel, notification } from 'antd'
import { convertMilliseconds } from '../../utils/units'
import { isEmptyObject } from '../../utils'
import { useSessionStorage } from '../../hooks/useSessionStorage'
import { NotificationBarContext, NotificationBarSetter } from '../../contexts/NotificationBar'
import { fetchSubscriptionUsage } from '../../store/companies/actions'

const NotificationBar = () => {
	const dispatch = useDispatch()
	const router = useRouter()
	const { pathname } = router
	const visible = useContext(NotificationBarContext)
	const setVisible = useContext(NotificationBarSetter)
	const { role, companyDetails } = useSelector(state => state.authReducer)
	const { subscriptionUsage } = useSelector(state => state.companiesReducer)
	const [content, setContent] = useState([])
	const [dismissedNotifications, setDismissedNotifications] = useSessionStorage('dismissedNotifications', [])
	const isAdmin = useMemo(() => {
		return role === claimsRoles.ADMIN
	}, [role])
	const isPurchaseOrderEnabled = useMemo(() => {
		return companyDetails?.isPurchaseOrderEnabled
	}, [companyDetails])
	const isECommerce = useMemo(() => {
		return companyDetails?.industry === industries.E_COMMERCE
	}, [companyDetails])
	const isInactiveSubscription = useMemo(() => {
		return companyDetails?.subscriptionStatus === SubscriptionStatus.PAST_DUE ||
			companyDetails?.subscriptionStatus === SubscriptionStatus.CANCELED
	}, [companyDetails])
	const isLegacySubscriptionPlan = useMemo(() => {
		return companyDetails?.subscriptionPlan === LEGACY_SUBSCRIPTION_PLAN
	}, [companyDetails])
	const isNewSubscriptionAllowed = useMemo(() => {
		return isECommerce &&
			(moment(companyDetails?.subscriptionStart).diff(moment(), 'days') > 30) &&
			companyDetails.subscriptionStatus === SubscriptionStatus.PAST_DUE
	}, [companyDetails, isECommerce])

	useEffect(() => {
		if (isECommerce && !isInactiveSubscription && !isLegacySubscriptionPlan) {
			getSubscriptionUsage(companyDetails.companyId)
		}
	}, [isECommerce, isInactiveSubscription, isLegacySubscriptionPlan])

	useEffect(() => {
		if (content.length && isECommerce) {
			setVisible(true)
		} else {
			setVisible(false)
		}
	}, [content, isECommerce])

	useEffect(() => {
		const hoursInThreeDays = 72
		const isSubscriptionPastDue = companyDetails?.subscriptionStatus === SubscriptionStatus.PAST_DUE
		const isSubscriptionCanceled = companyDetails?.subscriptionStatus === SubscriptionStatus.CANCELED
		const isSubscriptionExpiringSoon =
			companyDetails?.subscriptionStatus === SubscriptionStatus.INCOMPLETE &&
			moment(companyDetails?.subscriptionStart).add(7, 'days').diff(moment(), 'hours') < hoursInThreeDays
		const isTrialExpiringSoon =
			companyDetails?.subscriptionStatus === SubscriptionStatus.TRIALING &&
			moment(companyDetails?.subscriptionEnd).diff(moment(), 'hours') < hoursInThreeDays
		const isCompanyPage = pathname.includes(paths.COMPANY)
		if (!isAdmin && isECommerce && !isCompanyPage) {
			if (
				isSubscriptionPastDue &&
				!isNotificationBeingDisplayed(topNotificationType.SUBSCRIPTION_PAST_DUE)
			) {
				const notification = {
					type: topNotificationType.SUBSCRIPTION_PAST_DUE,
					dismissable: false,
					content: generateSubscriptionPastDueContent()
				}
				setContent(curr => [...curr, notification])
			}
			if (
				isSubscriptionCanceled &&
				!isNotificationBeingDisplayed(topNotificationType.SUBSCRIPTION_CANCELED)
			) {
				const notification = {
					type: topNotificationType.SUBSCRIPTION_CANCELED,
					dismissable: false,
					content: generateSubscriptionCanceledContent()
				}
				setContent(curr => [...curr, notification])
			}
			if (
				isSubscriptionExpiringSoon &&
				!isNotificationBeingDisplayed(topNotificationType.SUBSCRIPTION_EXPIRING) &&
				!hasNotificationBeenDismissed(topNotificationType.SUBSCRIPTION_EXPIRING)
			) {
				const notification = {
					type: topNotificationType.SUBSCRIPTION_EXPIRING,
					dismissable: true,
					content: generateSubscriptionExpiringContent(companyDetails?.subscriptionStart)
				}
				setContent(curr => [...curr, notification])
			}
			if (
				isTrialExpiringSoon &&
				!isNotificationBeingDisplayed(topNotificationType.SUBSCRIPTION_TRIAL_EXPIRING) &&
				!hasNotificationBeenDismissed(topNotificationType.SUBSCRIPTION_TRIAL_EXPIRING)
			) {
				const notification = {
					type: topNotificationType.SUBSCRIPTION_TRIAL_EXPIRING,
					dismissable: true,
					content: generateTrialExpiringContent(companyDetails?.subscriptionEnd)
				}
				setContent(curr => [...curr, notification])
			}
		} else {
			setContent([])
		}
	}, [isECommerce, companyDetails, pathname])

	useEffect(() => {
		if (!isEmptyObject(subscriptionUsage)) {
			const isSubscriptionPastDueOrCanceled =
				companyDetails?.subscriptionStatus === SubscriptionStatus.PAST_DUE ||
				companyDetails?.subscriptionStatus === SubscriptionStatus.CANCELED
			const salesOrderLimitAlmostOver = isSubscriptionLimitAlmostOver(companyDetails, subscriptionUsage, subscriptionLimit.SALES_ORDERS)
			const purchaseOrderLimitAlmostOver = isSubscriptionLimitAlmostOver(companyDetails, subscriptionUsage, subscriptionLimit.PURCHASE_ORDERS)
			const salesOrderLimitExceeded = hasSubscriptionLimitExceeded(companyDetails, subscriptionUsage, subscriptionLimit.SALES_ORDERS)
			const purchaseOrderLimitExceeded = hasSubscriptionLimitExceeded(companyDetails, subscriptionUsage, subscriptionLimit.PURCHASE_ORDERS)
			const isCompanyPage = pathname.includes(paths.COMPANY)
			if (
				salesOrderLimitExceeded &&
				!isCompanyPage &&
				!isSubscriptionPastDueOrCanceled &&
				!isNotificationBeingDisplayed(topNotificationType.SALES_ORDER_LIMIT_EXCEEDED) &&
				!hasNotificationBeenDismissed(topNotificationType.SALES_ORDER_LIMIT_EXCEEDED)
			) {
				const notification = {
					type: topNotificationType.SALES_ORDER_LIMIT_EXCEEDED,
					dismissable: true,
					content: generateSubscriptionLimitExceededContent(subscriptionLimit.SALES_ORDERS)
				}
				setContent(curr => [...curr, notification].filter(notification => notification.type !== topNotificationType.SALES_ORDER_LIMIT_ALMOST_OVER))
			}
			if (
				purchaseOrderLimitExceeded &&
				isPurchaseOrderEnabled &&
				!isCompanyPage &&
				!isSubscriptionPastDueOrCanceled &&
				!isNotificationBeingDisplayed(topNotificationType.PURCHASE_ORDER_LIMIT_EXCEEDED) &&
				!hasNotificationBeenDismissed(topNotificationType.PURCHASE_ORDER_LIMIT_EXCEEDED)
			) {
				const notification = {
					type: topNotificationType.PURCHASE_ORDER_LIMIT_EXCEEDED,
					dismissable: true,
					content: generateSubscriptionLimitExceededContent(subscriptionLimit.PURCHASE_ORDERS)
				}
				setContent(curr => [...curr, notification].filter(notification => notification.type !== topNotificationType.PURCHASE_ORDER_LIMIT_ALMOST_OVER))
			}
			if (
				salesOrderLimitAlmostOver &&
				!salesOrderLimitExceeded &&
				!isCompanyPage &&
				!isSubscriptionPastDueOrCanceled &&
				!isNotificationBeingDisplayed(topNotificationType.SALES_ORDER_LIMIT_ALMOST_OVER) &&
				!hasNotificationBeenDismissed(topNotificationType.SALES_ORDER_LIMIT_ALMOST_OVER)
			) {
				const notification = {
					type: topNotificationType.SALES_ORDER_LIMIT_ALMOST_OVER,
					dismissable: true,
					content: generateSubscriptionLimitAlmostOverContent(subscriptionLimit.SALES_ORDERS)
				}
				setContent(curr => [...curr, notification])
			}
			if (
				purchaseOrderLimitAlmostOver &&
				isPurchaseOrderEnabled &&
				!purchaseOrderLimitExceeded &&
				!isCompanyPage &&
				!isSubscriptionPastDueOrCanceled &&
				!isNotificationBeingDisplayed(topNotificationType.PURCHASE_ORDER_LIMIT_ALMOST_OVER) &&
				!hasNotificationBeenDismissed(topNotificationType.PURCHASE_ORDER_LIMIT_ALMOST_OVER)
			) {
				const notification = {
					type: topNotificationType.PURCHASE_ORDER_LIMIT_ALMOST_OVER,
					dismissable: true,
					content: generateSubscriptionLimitAlmostOverContent(subscriptionLimit.PURCHASE_ORDERS)
				}
				setContent(curr => [...curr, notification])
			}
		}
	}, [subscriptionUsage, pathname, isPurchaseOrderEnabled])

	const hasSubscriptionLimitExceeded = (companyDetails, subscriptionUsage, limitType) => {
		const usage = { ... subscriptionUsage }
		return usage[limitType]?.exceeded === true
	}

	const isSubscriptionLimitAlmostOver = (companyDetails, subscriptionUsage, limitType) => {
		const usage = { ... subscriptionUsage }
		return usage[limitType]?.count / usage[limitType]?.limit >= 0.9
	}

	const generateSubscriptionPastDueContent = () => {
		return (
			<div className={styles.contentContainer}>
				<span>
					{
						isNewSubscriptionAllowed ?
							<>
								<span className={styles.subscriptionMainUpdate}>Purchase a subscription plan to start using Nuport</span>
							</> :
							<>
								<span className={styles.subscriptionMainUpdate}>Your latest subscription invoice is</span>
								<span className={styles.subscriptionMainUpdateCanceled}>&nbsp;{'past due'}</span>
								<span className={styles.subscriptionMainUpdate}>{'. Please pay the invoice to continue using Nuport.'}</span>
							</>
					}
				</span>
				<span className={styles.notificationButtonContainer}>
					<Button
						type='primary'
						style={{ fontSize: '12px', padding: '2px 10px' }}
						onClick={() => router.push(paths.PAYMENTS)}
					>
						{isNewSubscriptionAllowed ? 'Subscribe' : 'Re-Subscribe'}
					</Button>
				</span>
			</div>
		)
	}

	const generateSubscriptionCanceledContent = () => {
		return (
			<div className={styles.contentContainer}>
				<span>
					<span className={styles.subscriptionMainUpdate}>Your subscription has</span>
					<span className={styles.subscriptionMainUpdateCanceled}>&nbsp;{'expired'}</span>
					<span className={styles.subscriptionMainUpdate}>{'. Please renew your subscription to continue using Nuport.'}</span>
				</span>
				<Button
					type='primary'
					style={{ fontSize: '12px', padding: '2px 10px' }}
					onClick={() => router.push(paths.PAYMENTS)}
				>
					{isNewSubscriptionAllowed ? 'Subscribe' : 'Re-Subscribe'}
				</Button>
			</div>
		)
	}

	const generateSubscriptionExpiringContent = (subscriptionStart) => {
		const timeTillExpiry = moment(subscriptionStart).add(7, 'days').diff(moment.tz())
		const converted = convertMilliseconds(Math.abs(timeTillExpiry), 'days-and-hours')
		return (
			<div className={styles.contentContainer}>
				<span>
					<span className={styles.subscriptionMainUpdate}>Your account is about to</span>
					<span className={styles.subscriptionMainUpdateCanceled}>&nbsp;{'expire'}</span>
					<span className={styles.subscriptionMainUpdate}>{`. You have ${converted.days} day(s) and ${converted.hours} hour(s) remaining. Please renew your subscription.`}</span>
				</span>
				<Button
					type='primary'
					style={{ fontSize: '12px', padding: '2px 10px' }}
					onClick={() => router.push(`${paths.PAYMENTS}/renew-subscription`)}
				>
					{isNewSubscriptionAllowed ? 'Subscribe' : 'Re-Subscribe'}
				</Button>
			</div>
		)
	}

	const generateTrialExpiringContent = (subscriptionEnd) => {
		const timeTillExpiry = moment(subscriptionEnd).diff(moment.tz())
		const converted = convertMilliseconds(Math.abs(timeTillExpiry), 'days-and-hours')
		return (
			<div className={styles.contentContainer}>
				<span>
					<span className={styles.subscriptionMainUpdate}>Your trial is about to</span>
					<span className={styles.subscriptionMainUpdateCanceled}>&nbsp;{'expire'}</span>
					<span className={styles.subscriptionMainUpdate}>{`. You have ${converted.days} day(s) and ${converted.hours} hour(s) remaining. Subscribe to a Nuport plan.`}</span>
				</span>
				<Button
					type='primary'
					style={{ fontSize: '12px', padding: '2px 10px' }}
					onClick={() => router.push(`${paths.PAYMENTS}/subscribe-now`)}
				>
					Subscribe Now
				</Button>
			</div>
		)
	}

	const generateSubscriptionLimitExceededContent = (limitType) => {
		return (
			<div className={styles.contentContainer}>
				<span>
					<span className={styles.notificationRedText}>Attention:</span>
					<span className={styles.subscriptionMainUpdate}>&nbsp;You&apos;ve reached your predefined&nbsp;</span>
					{
						limitType === subscriptionLimit.SALES_ORDERS &&
						<span className={styles.subscriptionMainUpdate}>Sales Orders usage&nbsp;</span>
					}
					{
						limitType === subscriptionLimit.PURCHASE_ORDERS &&
						<span className={styles.subscriptionMainUpdate}>Purchase Orders usage&nbsp;</span>
					}
					<span className={styles.notificationGreenText} onClick={() => router.push(`${paths.COMPANY}${paths.SETTINGS}`, null, { shallow: true })}>limits</span>
					<span className={styles.subscriptionMainUpdate}>{'. Please upgrade for uninterrupted access.'}</span>
				</span>
				<Button
					type='primary'
					style={{ fontSize: '12px', padding: '2px 10px' }}
					onClick={() => router.push(`${paths.PAYMENTS}`)}
				>
					Upgrade
				</Button>
			</div>
		)
	}

	const generateSubscriptionLimitAlmostOverContent = (limitType) => {
		return (
			<div className={styles.contentContainer}>
				<span>
					<span className={styles.notificationRedText}>Attention:</span>
					<span className={styles.subscriptionMainUpdate}>&nbsp;You&apos;ve reached 90% of your predefined&nbsp;</span>
					{
						limitType === subscriptionLimit.SALES_ORDERS &&
						<span className={styles.subscriptionMainUpdate}>Sales Orders usage&nbsp;</span>
					}
					{
						limitType === subscriptionLimit.PURCHASE_ORDERS &&
						<span className={styles.subscriptionMainUpdate}>Purchase Orders usage&nbsp;</span>
					}
					<span className={styles.notificationGreenText} onClick={() => router.push(`${paths.COMPANY}${paths.SETTINGS}`, null, { shallow: true })}>limits</span>
					<span className={styles.subscriptionMainUpdate}>{'. Please reach out to your Key Account Manager for personalized assistance or upgrade for uninterrupted access.'}</span>
				</span>
				<Button
					type='primary'
					style={{ fontSize: '12px', padding: '2px 10px' }}
					onClick={() => router.push(`${paths.PAYMENTS}`)}
				>
					Upgrade
				</Button>
			</div>
		)
	}

	const isNotificationBeingDisplayed = (type) => {
		return content.some(notification => notification.type === type)
	}

	const hasNotificationBeenDismissed = (type) => {
		return dismissedNotifications.includes(type)
	}

	const onDismissNotification = (type) => {
		setContent(curr => curr.filter(notification => notification.type !== type))
		setDismissedNotifications(curr => [...curr, type])
	}

	const getSubscriptionUsage = (companyId) => {
		try {
			dispatch(fetchSubscriptionUsage(companyId))
		} catch (e) {
			notification.error({
				message: 'Unable to Fetch Subscription Usage',
				description: e.message,
				placement: 'bottomLeft'
			})
		}
	}

	const renderCarouselContent = (notification = {}) => {
		return (
			notification?.content &&
			<div key={notification.type} className={styles.notificationContainer}>
				{notification?.content}
				{
					notification?.dismissable &&
					<svg className={styles.closeIcon} onClick={() => onDismissNotification(notification?.type)}>
						<use href='/img/close-2.svg#icon' />
					</svg>
				}
			</div>
		)
	}

	return (
		<>
			{
				visible &&
                <div className={styles.subscriptionUpdateBar}>
                	<div className={styles.subscriptionUpdateContainer}>
                		<Carousel dots={{ className: styles.notificationCarouselDots }} dotPosition='bottom' className={styles.notificationCarousel}>
                			{content.map(renderCarouselContent)}
                		</Carousel>
                	</div>
                </div>
			}
		</>
	)
}

NotificationBar.propTypes = {

}

export default NotificationBar
